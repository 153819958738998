import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-duotone-svg-icons"

const HeroAlternativeBlock = ({
  block,
  forceLoadImages = false,
  firstComponent = false,
}) => {
  return (
    <>
      {block.image?.filename?.length > 0 && (
        <BlockWrapper
          block={block}
          blockPadding=""
          blockWidth="container"
          showHeadline="false"
        >
          <div className="relative overflow-hidden bg-white">
            <Image
              image={block.image}
              title={block.title}
              alt={block.alt}
              className="hidden object-cover w-full h-auto aspect-[12/5] md:block"
              adaptToPixelRatio={true}
              forceLoad={forceLoadImages}
            />
            <Image
              image={block.image}
              title={block.title}
              alt={block.alt}
              className="block object-cover w-full h-auto aspect-[4/3] md:hidden"
              adaptToPixelRatio={true}
              forceLoad={forceLoadImages}
            />
            <div className="bg-transparent lg:inset-0 lg:absolute hero-gradient">
              <div className="h-full py-8 mx-auto lg:px-4">
                <div className="flex flex-col justify-between h-full lg:w-1/2">
                  <div>
                    <Headline
                      headline={block.headline}
                      className="pb-4 lg:text-white"
                      headlineLevel={firstComponent ? "h1" : "h2"}
                    />
                  </div>
                  <div>
                    <Richtext
                      className="lg:text-white lg:w-3/4"
                      text={block.text}
                    />
                    {block.link_text?.length > 0 && (
                      <Link className="mt-10 btn btn-primary" link={block.link}>
                        {block.link_text}
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          size="1x"
                          className="inline-block ml-2.5 md:-mr-1.5"
                        />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BlockWrapper>
      )}
    </>
  )
}

export default HeroAlternativeBlock
